import { pageEnum } from "../Common";

export const getListingUrl = (type) => {
  switch (type) {
    case pageEnum.Residentials:
      return "/residentials";
    case pageEnum.Commercials:
      return "/commercials";
    default:
      return "#";
  }
};

export const getListingName = (type) => {
  switch (type) {
    case pageEnum.Residentials:
      return "Residential Properties in Malta";
    case pageEnum.Commercials:
      return "Commercial Properties in Malta";
    default:
      return "";
  }
};

export const getQueryParam = (name, url) => {
  if (!url) url = document.location.href;
  name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
  var regexS = "[\\?&]" + name + "=([^&#]*)";
  var regex = new RegExp(regexS);
  var results = regex.exec(url);
  return results == null ? null : results[1];
};
