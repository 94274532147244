export const pageEnum = {
    Residentials: 6,
    Commercials: 7
}

export const sortingTypeEum = {
    Default: 0,
    PriceLowToHigh: 1,
    PriceHighToLow: 2,
    Popular: 3
}