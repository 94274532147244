import moment from "moment";
import React from "react";
import { Container, Grid, Icon, Image, Label } from "semantic-ui-react";
import api from "../../api";
import { AwsUrlBlog } from "../../helpers/aws";
import { isMobile } from "react-device-detect";

function BlogPost() {
  const [post, setPost] = React.useState(null);
  const slug = document.location.pathname.substring(1).split("/")[1];

  const load = async () => {
    const res = await api.blog.getPostBySlug(slug);
    if (res && res.result && res.result.blogPost?.length > 0) {
      const _post = res.result.blogPost[0];
      console.log("post: ", _post);
      setPost(_post);
      document.title = _post.title + " | Real Estate Agent in Malta";
      document
        .getElementsByClassName("link active item")?.[0]
        ?.classList?.remove("active");
      document
        .getElementById("desktop-menu-item-blog")
        ?.classList?.add("active");
    }
  };

  React.useEffect(() => {
    load();
  }, [slug]);

  const renderTitle = () => {
    if (!isMobile) {
      return (
        <Grid verticalAlign="middle" centered>
          <Grid.Column floated="left" width={5}>
            <h1>{post.title}</h1>
          </Grid.Column>
          <Grid.Column floated="right" textAlign="right" width={5}>
            <Label>
              <Icon name="calendar" />
              {moment(post.publishedDate).format("DD.MM.YYYY")}
            </Label>
          </Grid.Column>
        </Grid>
      );
    } else {
      return (
        <>
          <h1>{post.title}</h1>
          <Label>
            <Icon name="calendar" />
            {moment(post.publishedDate).format("DD.MM.YYYY")}
          </Label>
        </>
      );
    }
  };

  if (post == null) {
    return <div></div>;
  }

  return (
    <Container style={styles.container}>
      {renderTitle()}

      <Image src={AwsUrlBlog + post.imageURL} style={styles.image} />
      <div
        style={styles.content}
        dangerouslySetInnerHTML={{
          __html: post.content,
        }}
      ></div>
      <div style={styles.tags}>
        {post.tags?.split(",")?.map((tag, _) => (
          <Label
            as="a"
            tag
            color="orange"
            key={"tag-" + _}
            style={{ marginBottom: 2 }}
          >
            {tag}
          </Label>
        ))}
      </div>
    </Container>
  );
}

export default BlogPost;

const styles = {
  container: {
    paddingTop: "3rem",
    paddingBottom: "3rem",
    minHeight: "80vh",
  },
  image: {
    marginTop: 30,
    width: "100%",
  },
  content: {
    marginTop: 30,
  },
  tags: {
    marginTop: 30,
  },
};
