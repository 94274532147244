import React from 'react' 
import { CarouselProvider, Image, Slide, Slider } from "pure-react-carousel"
import SliderForm from './SliderForm'
import "pure-react-carousel/dist/react-carousel.es.css"


function SliderComp({ }) {

  const URL = 'https://easyrent.s3.eu-central-1.amazonaws.com/Easysale/ProjectFiles/'

  const slides = [
    { id: 1, imageSource: 'es_slider05.jpg' }
  ]

  const slider = () => (
    <CarouselProvider
      naturalSlideWidth={1}
      naturalSlideHeight={.3}
      totalSlides={1}
      infinite
      interval={5000}
      isPlaying
    >
      <Slider>
        {
          slides?.map(item =>
            <Slide tag='a' key={item.id}>
              <Image className="slider-image"
                src={URL + item.imageSource} /> 
            </Slide>
          )
        }
      </Slider>

      <SliderForm />
    </CarouselProvider>
  )
 
  return (
    <div>
      {
        slider()
      }
    </div>
  )
}

export default SliderComp
