import React from "react";
import { Label, Icon, Container, Loader, Button } from "semantic-ui-react";
import { useParams } from "react-router-dom";

import Slider from "./Slider";
import PropertiesSlider from "./PropertiesSlider";
import ButtonsBar from "./ButtonsBar";
import ContactAgentModal from "./ContactAgentModal";
import RequestViewingModal from "./RequestViewingModal";
import ShowLocationModal from "./ShowLocationModal";
import VideoModal from "./VideoModal";
import ModalSlider from "./ModalSlider";
import { isMobile } from "react-device-detect";
import ReactPlayer from "react-player";

import api from "../../api";
import { setRefId } from "../../helpers/cookie";

import "react-phone-input-2/lib/style.css";
import "./styles.scss";

const Detail = (props) => {
  const [modalSliderVisible, setModalSliderVisible] = React.useState(false);
  const [contactAgentModalVisible, setContactAgentModalVisible] =
    React.useState(false);
  const [requestViewingModalVisible, setRequestViewingModalVisible] =
    React.useState(false);
  const [showLocationModalVisible, setShowLocationModalVisible] =
    React.useState(false);
  const [showVideoModalVisible, setShowVideoModalVisible] =
    React.useState(false);
  const [data, setData] = React.useState({});
  const [form, setForm] = React.useState({});
  const [loading, setLoading] = React.useState(false);
  const [locationCenter, setLocationCenter] = React.useState({
    lat: 0,
    lng: 0,
  });

  const { id, type } = props;
  //const { type } = useParams() //lettingType

  React.useEffect(() => {
    window.scrollTo(0, 0);

    const script = document.createElement("script");
    script.async = true;

    script.type = "application/ld+json";

    const codeStr = `
      "@context": "https://schema.org",
      "@type": "RealEstateAgent",
      "name": "Easy Sale",
      "image": "https://www.easysale.com.mt/img/logo-orange01.png",
      "@id": "",
      "url": "https://www.easysale.com.mt/",
      "telephone": "99998330",
      "address": {
        "@type": "PostalAddress",
        "streetAddress": "420 Triq D'Argens",
        "addressLocality": "Gżira",
        "postalCode": "",
        "addressCountry": "MT"
      } ,
      "sameAs": [
        "https://www.facebook.com/easyrentmt/",
        "https://www.instagram.com/easyrent.malta/",
        "https://www.youtube.com/channel/UCjo81yeSg4l4JJ3qkl79Hfw",
        "https://www.linkedin.com/company/easyrent-malta/"
      ] 
  `;
    script.innerHTML = codeStr;

    document.body.appendChild(script);

    let url = window.location.href;
    let ref = url.split("?ref=");

    if (ref.length > 1) {
      setRefId(ref[1]);
    }
  }, []);

  React.useEffect(() => {
    const load = async () => {
      setLoading(true);
      const options = await api.properties.getPropertiesDetail(id, type);
      if (options?.result) {
        setData(options?.result);
        /* setLocationCenter({
          lat: options?.result.property.latitude,
          lng: options?.result.property.longitude,
        }); */
        //setRecommendations(options?.result.recommendations);
        setForm({
          ...form,
          // name: options?.result.property.homeShowUser.lastName,
          // phoneNumber: options?.result.property.homeShowUser.mobilePhone,
          // email: options?.result.property.homeShowUser.email,
          id: options?.result.property.id,
        });
      }
      setLoading(false);
    };

    load();
    //getProperties(1)
  }, []);

  const browserView = (item) => (
    <Label style={{ display: "flex", backgroundColor: "white" }}>
      <Icon color="orange" name="check" /> {item.name}
    </Label>
  );

  const mobilView = (item) => (
    <Label style={{ display: "flex", backgroundColor: "white" }}>
      <Icon color="orange" name="check" /> {item.name}
    </Label>
  );
  console.log("data ", data);
  return (
    <div>
      {loading ? (
        <div style={{ textAlign: "center" }}>
          <Loader active inline />
        </div>
      ) : (
        <div>
          <Slider
            data={data.property}
            photos={data.property?.photos}
            setModalSliderVisible={() =>
              setModalSliderVisible(!modalSliderVisible)
            }
          />

          <Container
            style={{
              marginTop: "2vh",
              color: "#353a47",
              paddingBottom: "50px",
            }}
          >
            <ButtonsBar
              openContactAgentModal={() => setContactAgentModalVisible(true)}
              openRequestViewingModal={() => setRequestViewingModalVisible(true) }
              openShowLocationModal={() => setShowLocationModalVisible(true)}
              referenceNumber={id} //reference number id olarak geliyomuş
              openVideoModal={() => setShowVideoModalVisible(true)}
              lettingType ={type}
              url={data.property?.videoURL}
            />

            {/*Property Features Box*/}
            {data.property?.features?.length > 0 && (
              <div className="property-feature-box">
                <h3 className="property-feature-title">Property Features</h3>
                <div className="property-feature-label">
                  {data.property?.features?.map((item) => {
                    return (
                      <div key={item.id}>
                        {isMobile ? mobilView(item) : browserView(item)}
                      </div>
                    );
                  })}
                </div>
              </div>
            )}

            {/*Property Description Box*/}
            <div className="property-feature-box">
              <h3 className="property-feature-title">{data.property?.title}</h3>
              <div className="property-feature-label">
                <h5 className="property-feature-desc">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: data.property?.description,
                    }}
                  />
                </h5>
              </div>
              {data.property?.videoURL !== null ? (
                <ReactPlayer
                  url={data.property?.videoURL}
                  width="100%"
                  height="400px"
                  // playIcon={true}
                  controls={true}
                />
              ) : null}
            </div>

            {/*Property Categories Box*/}
            {data.categories != null && data.categories.length > 0 ? (
              <div className="property-feature-box">
                <h3 className="property-feature-title">Categories</h3>
                <div className="property-feature-label">
                  {data.categories?.map((cat) => (
                    <Button
                      color="orange"
                      content={cat.name}
                      onClick={() => (document.location = "/" + cat.urlSlug)}
                    />
                  ))}
                </div>
              </div>
            ) : null}

            <ShowLocationModal
              lat={data.property?.latitude}
              lng={data.property?.longitude}
              center={{
                lat: data?.property?.latitude,
                lng: data?.property?.longitude,
              }}
              zoom={14}
              isOpen={showLocationModalVisible}
              onClose={() => setShowLocationModalVisible(false)}
            />

            <VideoModal
              isOpen={showVideoModalVisible}
              onClose={() => setShowVideoModalVisible(false)}
              url={data.property?.videoURL}
            />

            <PropertiesSlider
              title="Related Properties"
              items={data.recommendations || []}
              lettingType={data.property?.lettingType}
            />

            <ModalSlider
              isOpen={modalSliderVisible}
              onClose={() => setModalSliderVisible(false)}
              photos={data.property?.photos}
            />

            <ContactAgentModal
              isOpen={contactAgentModalVisible}
              onClose={() => setContactAgentModalVisible(false)}
              data={data.property?.homeShowUser}
              form={form}
              setForm={setForm}
            />
            <RequestViewingModal
              isOpen={requestViewingModalVisible}
              onClose={() => setRequestViewingModalVisible(false)}
              form={form}
              setForm={setForm}
              data={data.property?.homeShowUser}
            />
          </Container>
        </div>
      )}
    </div>
  );
};

export default Detail;
