import React, { useState, useEffect } from 'react'
import { Button, Label, Form, Card, Dropdown, Input, Icon, Grid } from 'semantic-ui-react'
import { isMobile } from 'react-device-detect'
import { pageEnum } from '../../Common'
import { useHistory } from 'react-router-dom'
import api from '../../api'

const lettingType = [
  { key: pageEnum.Residentials, text: 'Residential Sale', value: pageEnum.Residentials },
  { key: pageEnum.Commercials, text: 'Commercial Sale', value: pageEnum.Commercials },
]

const SliderForm = () => {

  const [options, setOptions] = useState({
    propertyTypes: [],
    areas: [],
    locations: [],
    locationsFullData: []
  })
  const [form, setForm] = useState({
    lettingType: 1,
    propertyTypes: [],
    referanceNumber: null,
    minPrice: null,
    maxPrice: null,
    areas: [],
    locations: [],
  })
  const [isSelectLettingType, setIsSelectLettingType] = useState(false)
  const [propertyType, setPropertyType] = useState([])

  const history = useHistory()
  let styling = isMobile ? {} : { position: 'absolute', top: '8%', right: '10%', zIndex: 1 }

  useEffect(() => {
    const load = async () => {
      const o = await api.properties.getFilterOptions()
      if (o?.result /* options.statusCode == 200 */) {
        // setOptions(optionss?.result)
        setPropertyType(o?.result.propertyTypes)
        const propTypes = mapPropertyTypes(o?.result.propertyTypes)
        const areas = mapAreas(o?.result.areas)
        const locations = mapLocations([areas[0]?.value], o?.result.locations)
        setOptions({
          ...options,
          propertyTypes: propTypes,
          areas,
          locations,
          locationsFullData: o?.result.locations
        })
      }
    }

    load()
  }, [])

  useEffect(() => {
    const propTypes = mapPropertyTypes(propertyType)
    setOptions({
      ...options,
      propertyTypes: propTypes,
    })
  }, [form.lettingType])

  const mapPropertyTypes = (data) => {
    let propTypes = []
     data = data.filter(x => x.lettingType == form.lettingType)
    data?.map(type => {
      propTypes.push({ key: type.id.toString(), value: type.id, text: type.name })
    })
    return propTypes
  }

  const mapAreas = (data) => {
    let areas = []
    data?.map(type => {
      areas.push({ key: type.id.toString(), value: type.id, text: type.name })
    })
    return areas
  }

  const mapLocations = (areaIds, data) => {
    let locations = []
    data?.map(type => {
      if (areaIds.indexOf(type.group.id) > -1) {
        locations.push({ key: type.id.toString(), value: type.id, text: type.name, groupid: type.groupId })
      }
    })
    return locations
  }

  const onClick = () => {

    const redirect = (pathName) => {
      history.push({
        pathname: pathName,
        state: { form: form }
      })
    }

    if (form.lettingType === null) {
      setIsSelectLettingType(true)
      return
    }

    switch (form?.lettingType) {
      case pageEnum.Residentials:
        redirect('/residentials')
        break;
      case pageEnum.Commercials:
        redirect('/commercials')
        break;
      default:
        redirect('/residentials')
    }
  }

  return (

    <Card style={{ ...styling }}>
      <Card.Content>
        <Form>
          {/* <Form.Field>
            <Grid style={{ margin: 0, padding: 0, width: '100%' }} >
              <Grid.Column width="7" style={{ padding: 0 }}>
                <Button
                  type='button'
                  fluid
                  color="orange"
                  basic={type != 'Residential'}
                  onClick={() => setType('Residential')}
                >Residential</Button>
              </Grid.Column>
              <Grid.Column width="7" floated='right' style={{ padding: 0 }}>
                <Button
                  type='button'
                  fluid
                  color="orange"
                  basic={type != 'Commercial'}
                  onClick={() => setType('Commercial')}
                >Commercial</Button>
              </Grid.Column>
            </Grid>
          </Form.Field> */}

          <Form.Field required>
            <Dropdown placeholder='Choose Sale Type' fluid selection options={lettingType}
              aria-label="choose-letting-type" 
              value={form.lettingType} onChange={(e, d) => setForm({ ...form, lettingType: d.value })} />
            {isSelectLettingType ? <Label color="red">Please Choose Sale Type </Label> : null}
          </Form.Field>

          <Form.Field>
            <Dropdown placeholder='Choose Multiple Property Types' fluid multiple selection options={options.propertyTypes}
              aria-label="choose-property-types" 
              onChange={(e, d) => setForm({ ...form, propertyTypes: d.value })} />
          </Form.Field>
          <Form.Field>
            <Dropdown placeholder='Choose Area' fluid multiple selection options={options.areas} value={form.areas}
              aria-label="choose-area"
              onChange={(e, { value }) => {
                const locations = mapLocations(value, options.locationsFullData)
                setOptions({ ...options, locations })
                try {
                  let locs = []
                  form?.locations?.map(loc => {
                    const locItem = options.locationsFullData.find(l => l.id == loc)
                    if (locItem == null) {
                      return
                    }
                    if (value?.indexOf(locItem.groupId) > -1) {
                      locs.push(loc)
                    }
                  })
                  setForm({ ...form, locations: locs, areas: value })
                } catch (error) {
                  console.log('error area ', error)
                }
              }} />
          </Form.Field>
          <Form.Field>
            <Dropdown placeholder='Choose Multiple Locations' fluid multiple selection options={options.locations} value={form.locations}
              aria-label="choose-location"
              onChange={(e, d) => setForm({ ...form, locations: d.value })} />
          </Form.Field>


          <Form.Field>
            <Grid style={{ margin: 0, padding: 0, width: '100%' }} >
              <Grid.Column width="7" style={{ padding: 0 }}>
                <Input iconPosition='left' type='text' placeholder='min.' onChange={(e, d) => setForm({ ...form, minPrice: d.value })}>
                  <Icon name='euro' />
                  <input />
                </Input>
              </Grid.Column>
              <Grid.Column width="2" style={{ padding: 0, textAlign: 'center', marginTop: 10 }}>to</Grid.Column>
              <Grid.Column width="7" floated='right' style={{ padding: 0 }}>
                <Input iconPosition='left' type='text' placeholder='max.' onChange={(e, d) => setForm({ ...form, maxPrice: d.value })}>
                  <Icon name='euro' />
                  <input />
                </Input>
              </Grid.Column>
            </Grid>
          </Form.Field>
          {/* <Form.Field>
            <Checkbox label='I agree to the Terms and Conditions' />
          </Form.Field> */}

          <Button type='submit' onClick={onClick} fluid color="orange">Search</Button>

          {/* <Form.Field>
            <Label sty>
              {isSelectLettingType}
            </Label>
          </Form.Field> */}
        </Form>
      </Card.Content>
    </Card>

  )
}

export default SliderForm
