import React, { Suspense } from "react";
import {
  Container,
  Grid,
  Breadcrumb,
  Dropdown,
  Icon,
  Ref,
  Pagination,
  Loader,
  Rail,
  Sticky,
  Menu,
  Button
} from "semantic-ui-react";
import { isMobile, MobileView } from "react-device-detect";
import FilterForm from "./FilterForm";
import ListItem from "./ListItem";
import MostPopularPropertyTypes from "./MostPopularPropertyTypes";
import { getCookie, setCookie } from "../../helpers/cookie";
import { pageEnum, sortingTypeEum } from "../../Common";
import { getListingName } from "../../helpers/common";

const contextRef = React.createRef();

const List = (props) => {
  let pageNumber = getCookie("listingPageNumber");
  if (pageNumber == null || pageNumber == "") {
    pageNumber = 0;
  }

  const [filterAccordion, setFilterAccordion] = React.useState(false);
  const [activePage, setActivePage] = React.useState(pageNumber);
  const [filterFormData, setFilterFormData] = React.useState();
  const [lettingType, setLettingType] = React.useState(null);
  const [sortingType, setSortingType] = React.useState(sortingTypeEum.Default);
  const [labelSort, setLabelSort] = React.useState("Sort");
  const [mapOpen, setMapOpen] = React.useState(false);
  const [form, setForm] = React.useState({});
  const openInNewTab = url => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };
  // const ListItem = React.lazy(() => import('./ListItem'))

  const filter = (filterData) => {
    setActivePage(0);
    props.getProperties(0, filterData);
    setFilterFormData(filterData);
    setCookie("listingPageNumber", 0, 1);
  };

  const renderBreadcrumb = () => (
    <Breadcrumb>
      <Breadcrumb.Section link>Home</Breadcrumb.Section>
      <Breadcrumb.Divider />
      <Breadcrumb.Section active>Sale</Breadcrumb.Section>
    </Breadcrumb>
  );

  const getPageName = () => {
    if (props.category) {
      return props.category.name;
    }
    switch (window.location.pathname) {
      case "/commercials":
        return "Commercial Properties in Malta";
      case "/residentials":
        return "Residential Properties";
      default:
        return "";
    }
  };

  const renderFilterBar = (sticky = false) => {
    if (sticky) {
      {
        /* Mobile */
      }
      return (
        <Rail position="top">
          <Sticky>
            <Menu fixed="top" style={{ marginTop: "12%" }}>
              <Grid style={styles(mapOpen).listHeader}>
                {!isMobile ? null : (
                  <Grid.Column mobile="5">
                    <a
                      class="mb-filter-button"
                      onClick={() => {
                        setFilterAccordion(!filterAccordion);
                        window.scrollTo({ top: 0, behavior: "smooth" });
                      }}
                    >
                      <Icon name="filter" /> Filter
                    </a>
                  </Grid.Column>
                )}
                <Grid.Column computer={8} mobile="5">
                  <Icon name="sort alphabet ascending" />
                  <Dropdown text={labelSort}>
                    <Dropdown.Menu>
                      <Dropdown.Item
                        text="Newest"
                        onClick={() => {
                          setLabelSort("Sort");
                          setSortingType(sortingTypeEum.Default);
                        }}
                      />
                      <Dropdown.Item
                        text="Price Low to High"
                        onClick={() => {
                          setLabelSort("Price Low to High");
                          setSortingType(sortingTypeEum.PriceLowToHigh);
                        }}
                      />
                      <Dropdown.Item
                        text="Price High to Low"
                        onClick={() => {
                          setLabelSort("Price High to Low");
                          setSortingType(sortingTypeEum.PriceHighToLow);
                        }}
                      />
                      <Dropdown.Item
                        text="Popular"
                        onClick={() => {
                          setLabelSort("Popular");
                          setSortingType(sortingTypeEum.Popular);
                        }}
                      />
                    </Dropdown.Menu>
                  </Dropdown>
                </Grid.Column>
                
              </Grid>
            </Menu>
          </Sticky>
        </Rail>
      );
    }

    {
      /* Desktop */
    }
    return (
      <Menu
        fixed={mapOpen ? "" : ""}
        style={{
          marginTop: mapOpen ? "" : "0rem",
          marginBottom: mapOpen ? "" : "-5rem",
          //marginLeft: mapOpen ? "" : "5%",
          width: mapOpen ? "" : "100%",
        }}
      >
        <Grid style={styles(mapOpen).listHeader}>
          {!isMobile ? null : (
            <Grid.Column mobile="5">
              <a
                class="mb-filter-button"
                onClick={() => {
                  setFilterAccordion(!filterAccordion);
                  window.scrollTo({ top: 0, behavior: "smooth" });
                }}
              >
                <Icon name="filter" /> Filter
              </a>
            </Grid.Column>
          )}
          <Grid.Column computer={8} mobile="5">
            <Icon name="sort alphabet ascending" />
            <Dropdown text={labelSort}>
              <Dropdown.Menu>
                <Dropdown.Item
                  text="Newest"
                  onClick={() => {
                    setLabelSort("Sort");
                    setSortingType(sortingTypeEum.Default);
                  }}
                />
                <Dropdown.Item
                  text="Price Low to High"
                  onClick={() => {
                    setLabelSort("Price Low to High");
                    setSortingType(sortingTypeEum.PriceLowToHigh);
                  }}
                />
                <Dropdown.Item
                  text="Price High to Low"
                  onClick={() => {
                    setLabelSort("Price High to Low");
                    setSortingType(sortingTypeEum.PriceHighToLow);
                  }}
                />
                <Dropdown.Item
                  text="Popular"
                  onClick={() => {
                    setLabelSort("Popular");
                    setSortingType(sortingTypeEum.Popular);
                  }}
                />
              </Dropdown.Menu>
            </Dropdown>
          </Grid.Column>
        </Grid>
      </Menu>
    );
  };

  //Sale Properties
  return (
    <Container style={styles(mapOpen).container} fluid>
      <div>
        {/* {renderBreadcrumb()} */}
        <h1
          style={{
            fontSize: isMobile ? "1.5rem" : null,
            marginTop: isMobile ? 50 : 0,
          }}
        >
          {getPageName()}
        </h1>
      </div>
      <div style={{ height: isMobile ? 0 : 15 }} />
      <Ref innerRef={contextRef}>
        <Grid>
          <Grid.Row>
            {isMobile ? null : (
              <Grid.Column computer={3}>
                {/* <Sticky context={contextRef} offset={100}> */}
                <FilterForm
                  options={props.filterOptions}
                  filter={filter}
                  forms={props.form}
                  setFilterForm={props.setFilterForm}
                  setLettingType={setLettingType}
                  pageNumber={props.pageNumber}
                  sortingType={sortingType}
                  category={props.category}
                />
                {/* </Sticky> */}
              </Grid.Column>
            )}
            <Grid.Column computer={13} mobile={16}>
              {renderFilterBar(isMobile)}
              <MobileView>
                {/* {filterAccordion ? */}
                <div style={{ display: filterAccordion ? "block" : "none" }}>
                  <FilterForm
                    options={props.filterOptions}
                    filter={filter}
                    forms={props.form}
                    setFilterForm={props.setFilterForm}
                    setLettingType={setLettingType}
                    pageNumber={props.pageNumber}
                    sortingType={sortingType}
                    setFilterAccordion={() =>
                      setFilterAccordion(!filterAccordion)
                    }
                    category={props.category}
                  />
                </div>
                {/* /> : null} */}
              </MobileView>

              <Container fluid style={styles(mapOpen).items}>
                {props.propertiesLoading ? (
                  <div style={{ textAlign: "center" }}>
                    <Loader active inline />
                  </div>
                ) : null}
                <>
                  <Grid>
                    <Grid.Row>
                      {props.properties?.map((item, index) => (
                        <Grid.Column
                          width="16"
                          computer={4}
                          mobile={16}
                          key={"property-" + item.id}
                        >
                          {/* <Suspense fallback={<div></div>}> */}
                          <ListItem {...item} />
                          {/* </Suspense> */}
                        </Grid.Column>
                      ))}
                    </Grid.Row>
                  </Grid>
                </>

                <Pagination
                  style={{ width: "100%" }}
                  // defaultActivePage={activePage}//berk yorum satına aldı
                  totalPages={Math.ceil(props.total / 20)}
                  activePage={activePage + 1}
                  ellipsisItem={true}
                  onPageChange={(e, { activePage }) => {
                    if (activePage <= 0) {
                      return;
                    }
                    let pageIndex = activePage - 1;
                    props.getProperties(pageIndex, filterFormData);
                    setActivePage(pageIndex);
                    setCookie("listingPageNumber", pageIndex, 1);
                    window.scrollTo(0, 0);
                  }}
                />
                {props.category && props.category.description && (
                  <div style={{ marginTop: 20 }}>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: props.category.description,
                      }}
                    ></div>
                  </div>
                )}
                {window.location.pathname === "/residentials" && (
                  <div style={{ marginTop: 20 }}>
                    <div
                      dangerouslySetInnerHTML={{ 
                        __html: "<h2>Property for Residential Sale in Malta</h2>"+
                        "<p>Buying property in Malta is financially easy, but you have to meet certain conditions. These conditions are very important. You should carefully read and obey them. Let's look more closely at these circumstances. After this part, there are a few more important places you should know. We will tell them too. Property purchases and sales in Malta are governed by local regulations. Here are some points to be aware of before making your first investment here: </p>"+
                        "<ul><li>All contracts should be drafted in English. </li>"+
                        "<li>After signing the agreement, the buyer will deposit a payment equal to 10% of the purchase price with the Notary. </li>"+
                        "<li>If the buyer fails to complete the deed, the deposited sum will be returned to the seller, provided there is a sufficient legal justification for failure to finish. </li>"+
                        "<li>By default, the term of such a contract is three months, but parties might agree to a different validity period. </li>"+
                        "<li>When the buyer takes ownership of the property, they must pay customs duty and other legal fees. </li></ul>"+
                        "<h2>Purchasing Real Estate in Malta as a Foreigner</h2>"+
                        "<p>Malta, which consists of three islands, is located in the heart of the Mediterranean. There are various historic sites scattered around the islands, including carved stone templates and massive strongholds. Historic sites, and universe marina, a great climate, and a distinct culture make it an appealing destination for property investors. </p>"+
                        "<p>Malta is an excellent area to invest in real estate, particularly if you are an EU citizen. With new housing developments, it's no surprise that Malta continues to captivate property investors from around the world. In Malta, real estate is a valuable asset. A one bedroom apartment on the water can cost up to €200,000. </p>"+
                        "<p>Rental options in cosmopolitan but instead historic sections of the country will also thrill investors searching for rental income, thanks to a thriving tourism economy. Malta is a popular retirement location as well as a fantasy holiday destination. The government provides appealing retirement and residency schemes. Thousands of EU residents take up residence in Malta each year as a result of these schemes, contributing to the real estate industry's steady expansion. </p>"+
                        "<h2>What Should You Know Before Buying a Property in Malta? </h2>"+
                        "<p>Purchasing real estate in Malta is a sensible investment. Property in Malta appears to maintain long-term growth, thanks to demand from both domestic and international buyers. Those wishing to buy a home can select between ancient homes and new luxury apartments. Purchasing property within Malta is typically a simple process. Here's how a transaction goes down: </p>"+
                        "<ul><li>You locate and select a property to purchase. </li>"+
                        "<li>An architect may inspect the property's structure on your behalf. </li>"+
                        "<li>You and the vendor reach an agreement on the price and terms. Once agreed upon, both <li>parties will file an agreement to buy and sell real estate. </li>"+
                        "<li>Once signed, the commitment will be binding on the parties. </li>"+
                        "<li>A Notary Public will examine the title & prepare the final document of sale. It is important that this is so. It is of great importance in terms of protecting the rights of both parties. </li> </ul>"
                      }}
                    ></div>
                  </div>
                )}
                {window.location.pathname === "/commercials" && (
                  <div style={{ marginTop: 20 }}>
                    <div
                      dangerouslySetInnerHTML={{ 
                        __html: "<h2>Property for Commercial Sale in Malta</h2>"+
                        "<p>Buying property in Malta is financially easy, but you have to meet certain conditions. These conditions are very important. You should carefully read and obey them. Let's look more closely at these circumstances. After this part, there are a few more important places you should know. We will tell them too. Property purchases and sales in Malta are governed by local regulations. Here are some points to be aware of before making your first investment here: </p>"+
                        "<ul><li>All contracts should be drafted in English. </li>"+
                        "<li>After signing the agreement, the buyer will deposit a payment equal to 10% of the purchase price with the Notary. </li>"+
                        "<li>If the buyer fails to complete the deed, the deposited sum will be returned to the seller, provided there is a sufficient legal justification for failure to finish. </li>"+
                        "<li>By default, the term of such a contract is three months, but parties might agree to a different validity period. </li>"+
                        "<li>When the buyer takes ownership of the property, they must pay customs duty and other legal fees. </li></ul>"+
                        "<h2>Purchasing Real Estate in Malta as a Foreigner</h2>"+
                        "<p>Malta, which consists of three islands, is located in the heart of the Mediterranean. There are various historic sites scattered around the islands, including carved stone templates and massive strongholds. Historic sites, and universe marina, a great climate, and a distinct culture make it an appealing destination for property investors. </p>"+
                        "<p>Malta is an excellent area to invest in real estate, particularly if you are an EU citizen. With new housing developments, it's no surprise that Malta continues to captivate property investors from around the world. In Malta, real estate is a valuable asset. A one bedroom apartment on the water can cost up to €200,000. </p>"+
                        "<p>Rental options in cosmopolitan but instead historic sections of the country will also thrill investors searching for rental income, thanks to a thriving tourism economy. Malta is a popular retirement location as well as a fantasy holiday destination. The government provides appealing retirement and residency schemes. Thousands of EU residents take up residence in Malta each year as a result of these schemes, contributing to the real estate industry's steady expansion. </p>"+
                        "<h2>What Should You Know Before Buying a Property in Malta? </h2>"+
                        "<p>Purchasing real estate in Malta is a sensible investment. Property in Malta appears to maintain long-term growth, thanks to demand from both domestic and international buyers. Those wishing to buy a home can select between ancient homes and new luxury apartments. Purchasing property within Malta is typically a simple process. Here's how a transaction goes down: </p>"+
                        "<ul><li>You locate and select a property to purchase. </li>"+
                        "<li>An architect may inspect the property's structure on your behalf. </li>"+
                        "<li>You and the vendor reach an agreement on the price and terms. Once agreed upon, both <li>parties will file an agreement to buy and sell real estate. </li>"+
                        "<li>Once signed, the commitment will be binding on the parties. </li>"+
                        "<li>A Notary Public will examine the title & prepare the final document of sale. It is important that this is so. It is of great importance in terms of protecting the rights of both parties. </li> </ul>"
                      }}
                    ></div>
                  </div>
                )}

                {props.category && props.category.mostPopularPropertyTypes && props.category.mostPopularPropertyTypes?.length > 0 && (
                  <MostPopularPropertyTypes mostPopularPropTypes={props.category.mostPopularPropertyTypes} />
                )}
              </Container>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Ref>
    </Container>
  );
};

export default List;

const styles = (mapOpen) => ({
  container: {
    paddingTop: isMobile ? 10 : 30,
    paddingLeft: isMobile ? 0 : 20,
    paddingRight: isMobile ? 0 : 20,
  },
  listHeader: {
    margin: 0,
    padding: isMobile ? 0 : 5,
    backgroundColor: "#fff",
    borderRadius: 3,
    border: "1.5px solid #ccc",
    width: "100%",
  },
  items: {
    marginTop: isMobile ? 15 : 85,
  },
  iconButton: {
    backgroundColor: "#353a47",
    margin: 5
  },
  button: {
    backgroundColor: "#353a47",
    margin: 5
  },
  referenceNumber: {
    backgroundColor: "#fff"
  },
});
