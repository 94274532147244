import React, { useState } from "react";
import styled from "styled-components";
import { useLayer } from "react-laag";
import { Image, Card, Icon } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import { pageEnum } from '../../../Common'
import { isMobile } from 'react-device-detect'
import moment from 'moment'

import { AwsUrl } from '../../../helpers/aws'


const StyledMarker = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  padding:5px;
  width: 60px;
  // height: 20px;
  // background-color: lightorange;
  border: 1px solid; 
  border-radius: 15%;
  transform: translate(-50%, -50%);
  &:hover {
    z-index: 1;
  }
`;
const InfoBox = styled.div` 
width:280px;
  box-shadow: 1px 1px 10px 1px rgba(0, 0, 0, 0.2);
  background-color: #fff;
`;

function getPrice(long, short, commercional, lettingType) {
  let temp = 0
  if (lettingType === pageEnum.Residentials) {
    temp = long
  } else if (lettingType === pageEnum.Commercials) {
    temp = commercional
  } else {
    temp = short
  }
  return temp
}

export default function Marker({ name, item }) {


  let duration = moment.duration(moment(item.availableDate).diff(moment()))
  const diff = duration.asDays() <= 0 ? '0' : '1'//0 ise now(#21ba45), 1 ise soon(#f2711c)

  const [isOpen, setOpen] = useState(false);

  const { triggerProps, layerProps, arrowProps, renderLayer } = useLayer({
    isOpen,
    triggerOffset: 10,
    auto: true,
    overflowContainer: false,
    onOutsideClick: () => setOpen(false),
    container: "infoboxlayer",
    onDisappear: (type) => {
      if (type === "full") setOpen(false);
    }
  });

  const DesktopView = () => (
    <Link target="_blank" to={'/detail/' + item.id + '/' + item.lettingType}>
      <Card style={{ width: '100%', zIndex: 999 }}>
        {/* <Image src={'https://easyrent.com.mt' + item.photos[0].thumbnail} */}
        <Image src={AwsUrl + item.thumbnailImageUrl}
          wrapped ui={false} style={{ height: '200px' }} />
        <Card.Content>
          <Card.Header style={{ color: 'orange' }}>{item.typeName}</Card.Header>

          <Card.Header style={{ marginTop: 5 }}>{item.title}</Card.Header>

          <Card.Meta>
            <Icon name='map pin' color="orange" style={{ marginTop: 15 }} /> {item.locationName}
          </Card.Meta>
          <Card.Description>
            Bedrooms: {item.bedrooms} Bathrooms: {item.bathrooms}
          </Card.Description>
        </Card.Content>

        <Card.Content>
          <a style={{ color: '#000', fontSize: 'large' }}><Icon name='euro' />
            {item.price}</a>
        </Card.Content>
      </Card>
    </Link>
  )

  return (
    <>
      <StyledMarker style={{ backgroundColor: diff === '0' ? '#FE7516' : '#f2711c' }}
        //onMouseOver={() => setOpen(true)}
        //onMouseLeave={() => setOpen(false)}
        onClick={() => setOpen(!isOpen)}
        {...triggerProps}
      >
        <h5
          style={{
            paddingRight: "5px", paddingLeft: "5px", whiteSpace: "nowrap",
            color: diff === '0' ? '#000' : '#fff',
          }}>
          {item.price} €</h5>
      </StyledMarker>
      {isOpen &&
        renderLayer(
          <InfoBox
            {...layerProps}
            style={{ ...layerProps.style }}
          >
            {!isMobile ? <DesktopView /> : null}
          </InfoBox>
        )
      }
    </>
  );
}
