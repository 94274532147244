import caller from './Caller'
 
export const home = async () => {
  const { data } = await caller({
    method: 'GET',
    url: '/EasysaleHome'
  })

  return data
}
